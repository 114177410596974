import { initGallery } from '../components/Gallery';
import {
	addClass,
	button,
	el,
	els,
	isEl,
	onClick,
	onReady,
	removeClass,
	scan,
} from '../util';

export function initArticle() {
	if (isEl('html.article')) {
		onReady(() => {
			initGallery();
			initProgress();
			initToc();
			initSpotifyPlayer();
			initGalleryOpen();
			initShareButton();
			initLikeButton();
		});
	}
}

const tocCssSelector =
	'article .separator, article h2, article h3:not(.card-title), article>h4';

function initToc() {
	if (isEl('#toc nav')) {
		renderToc(el('#toc nav'));

		initTocPosition();
		initTocObserver();
	}
}

function renderToc(tocNav: Element) {
	// Rövid cikknél nincs értelme progressbarnak
	// Ne csak a scrollon legyen rajta, hanem a loadon és resize-on is

	tocNav.innerHTML = '';

	scan(tocCssSelector, (item, i) => {
		item.setAttribute('id', 'toc-' + i);

		tocNav.innerHTML += `<a class="${item.className}" id="toc-link-${i}" href="#toc-${i}">${item.innerText}</a>`;
	});
}

function initTocObserver() {
	window.addEventListener('scroll', (e) => {
		scan(tocCssSelector, (item, i) => {
			const id = '#toc-link-' + i;

			if (inViewport(item)) {
				addClass(id, 'actual');
			} else {
				removeClass(id, 'actual');
			}
		});
	});
}

function initTocPosition() {
	const toc = el('#toc');

	const tocOriginalTop = parseInt(
		window.getComputedStyle(toc).getPropertyValue('top')
	);

	window.addEventListener('scroll', (e) => {
		const scrollY = window.scrollY;
		const article = el('article');

		const tocBoundHeight = toc.getBoundingClientRect().height;

		const tocBottom = article.offsetHeight - tocBoundHeight;

		if (scrollY + 32 > tocOriginalTop) {
			toc.style.top = '32px';
			toc.style.position = 'fixed';
		} else {
			toc.setAttribute('style', '');
		}

		if (scrollY > tocBottom) {
			toc.style.top = tocBottom + 'px';
			toc.style.position = 'absolute';
		}
	});
}

function initProgress() {
	const article = el('article');

	window.addEventListener('scroll', (e) => {
		const scrollY = window.scrollY;
		const progressbar = el('#article-progress progress');
		const bottom = article.offsetHeight - window.innerHeight;

		const percent = Math.min(100, (scrollY / bottom) * 100);

		progressbar.setAttribute('value', percent.toString());
		progressbar.textContent = Math.round(percent) + '%';
	});
}

function inViewport(element) {
	const rect = element.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <=
			(window.innerWidth || document.documentElement.clientWidth)
	);
}

function initSpotifyPlayer() {
	const writing = globalThis.writing;

	if (isEl('#audio-button')) {
		const button = el('#audio-button');

		globalThis.onSpotifyIframeApiReady = (IFrameAPI) => {
			const element = el('#embed-iframe');
			const container = el('#audio-container');

			const options = {
				width: '100%',
				height: 232,
				uri: parseSpotifyUrl(writing.audioEmb),
			};

			const callback = (EmbedController) => {
				button.addEventListener(
					'click',
					() => {
						container.style.display = 'block';
						EmbedController.play();
					},
					{ once: true }
				);
			};

			IFrameAPI.createController(element, options, callback);
		};
	}
}

// TODO: validation for uri
function parseSpotifyUrl(url: string) {
	return `spotify${url
		.split('https://open.spotify.com')[1]
		.replace(/\//g, ':')}`;
}

function initGalleryOpen() {
	const images = el('#gallery-images').children;

	scanGalleryTriggers(images);
}

function addButtonListener(element: HTMLElement, images: HTMLCollection) {
	const buttonId = parseInt(element.getAttribute('data-id'));

	const galleryImage = getGalleryImage(buttonId, images) as HTMLElement;

	if (element && images.length > 0) {
		element.onclick = () => imageClick(galleryImage);
	}
}

function imageClick(galleryImage: HTMLElement) {
	galleryImage.click();

	return false;
}

function scanGalleryTriggers(images: HTMLCollection) {
	scan('.gallery-button', (el) => {
		addButtonListener(el, images);
	});
}

function getGalleryImage(id: number, images: HTMLCollection) {
	const elements = Array.from(images);

	const result = elements.find(
		(el) => parseInt(el.getAttribute('data-id')) === id
	);

	return result ? result : elements[0];
}

function initShareButton() {
	const shareButton = document.getElementById('share-button');

	const textNode = shareButton.childNodes[0];
	textNode.textContent = navigator.share ? 'Megosztás' : 'Link másolása';

	shareButton.addEventListener('click', (event) => {
		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					url: window.location.href,
				})
				.catch(console.error);
		} else {
			navigator.clipboard.writeText(window.location.href).then(() => {
				// info to user?
				console.log('Copied to clipboard!');
			});
		}
	});
}

function initLikeButton() {
	const writingId = globalThis.writing.id;
	const likeButton = button('#like-button');

	const item = localStorage.getItem(`liked_${writingId}`);

	//likeButton.ariaPressed = item && item == 'true' ? 'true' : 'false';
	likeButton.setAttribute(
		'aria-pressed',
		item && item == 'true' ? 'true' : 'false'
	);

	onClick('#like-button', () => {
		if (likeButton.getAttribute('aria-pressed') == 'true') {
			likeButton.setAttribute('aria-pressed', 'false');
			//likeButton.ariaPressed = 'false';
			localStorage.setItem(`liked_${writingId}`, 'false');
		} else {
			likeButton.setAttribute('aria-pressed', 'true');
			//likeButton.ariaPressed = 'true';
			localStorage.setItem(`liked_${writingId}`, 'true');
		}
	});
}
