import autoComplete from '@tarekraafat/autocomplete.js';

export function initAutocomplete() {
	const autoCompleteJS = new autoComplete({
		//placeHolder: 'Search for Food...',
		selector: '#search-input',
		threshold: 2,
		debounce: 350,
		data: {
			src: async (text) => {
				const result = await query(text);

				console.log('ASYNC RESULT', result);
				console.log('ASYNC EVENT', text);

				return result;
			},
			keys: ['value'],
			cache: false,
			filter: (list) => {
				console.log('list', list);
				return list;
			},
		},
		resultItem: {
			element: (item: HTMLElement, data) => {
				console.log('resultItem', { item, data });

				if (data.value.type === 'Keyword') {
					item.classList.add('keyword');
				}
			},
			highlight: true,
		},
		events: {
			input: {
				focus() {
					if (autoCompleteJS.input.value.length) {
						console.log('events / input / focus', autoCompleteJS);

						autoCompleteJS.start();
					}
				},
				selection(event) {
					console.log('events / input / selection', event);

					const selected = event.detail.selection.value;

					if (selected.type === 'FreeText') {
						autoCompleteJS.input.value = selected.value;
					} else {
						const keyword = window.keywords.find(
							(el) => el.name === selected.value
						);

						if (keyword) {
							window.location.href = `/kulcsszo${keyword.seoName}`;
						} else {
							alert('Kulcsszó nem található!');
							console.log('Keyword not found', selected.value);
						}
					}
				},
			},
		},
	});
}

function query(text: string): Promise<any> {
	const url = `/suggest?text=${text}`;

	return fetch(url)
		.then((response) => response.json())
		.then((data) => {
			console.log(data);

			const result = parse(data);
			console.log('result', result);

			return result;
		});
}

function parse(response) {
	const result: any = {};

	Object.keys(response.suggest).forEach((key) => {
		const source = response.suggest[key];
		const sourceData = source[Object.keys(source)[0]].suggestions;

		const items = sourceData.map((el) => ({
			...el,
			value: el.term.replaceAll('\u001e', ' '),
			type: key,
		}));

		result[key] = items;
	});

	if (result.Keyword.length > 3) {
		if (result.FreeText.length < 3) {
			result.Keyword = result.Keyword.slice(
				0,
				3 + (3 - result.FreeText.length)
			);
		} else {
			result.Keyword = result.Keyword.slice(0, 3);
		}
	}

	return [...result.Keyword, ...result.FreeText];
}
