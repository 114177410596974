import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import CookiePanel from './components/CookiePanel';
import { initArticle } from './page/article';
import { initSearch } from './page/search';
import { initLanding } from './page/landing';

// Vanilla

initArticle();
initSearch();
initLanding();

// Alpine

globalThis.Alpine = Alpine;

Alpine.plugin(focus);

Alpine.data('CookiePanel', CookiePanel);

Alpine.start();

// React
