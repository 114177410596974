import { initAutocomplete } from '../components/Autocomplete';

import { form, input, isEl, onEvent, onReady } from '../util';

export function initLanding() {
	if (isEl('html.landing')) {
		onReady(() => {
			initAutocomplete();
			submitOnEnter();
		});
	}
}

function submitOnEnter() {
	onEvent('#search-input', 'keyup', (el, event) => {
		if (event.key === 'Enter') {
			form('#searchForm').submit();
		}
	});
}
