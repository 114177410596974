import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export function initGallery() {
	const lightbox = new PhotoSwipeLightbox({
		gallery: '#gallery-images',
		children: 'a',
		pswpModule: PhotoSwipe,
		closeTitle: 'Bezárás',
		zoomTitle: 'Nagyítás',
		arrowPrevTitle: 'Előző',
		arrowNextTitle: 'Következő',
	});

	lightbox.init();
}
