import { initAutocomplete } from '../components/Autocomplete';
import { initPager } from '../components/Pager';
import {
	initMissSpell,
	initSearchForm,
	initSwitchMode,
	initTopicFilters,
} from '../components/SearchForm';

import { isEl, onReady } from '../util';

export function initSearch() {
	if (isEl('html.search')) {
		onReady(() => {
			initSearchForm();
			initTopicFilters();
			initSwitchMode();
			initPager();
			initAutocomplete();
			initMissSpell();
		});
	}
}
