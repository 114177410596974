import { form, input, inputs, isEl, onClick, scan, scanInputs } from '../util';

export function initSearchForm() {
	form('#searchForm').addEventListener('submit', () => prepareSubmit());
	submitOnEnter();
}

export function initTopicFilters() {
	scanInputs('.topic-filter', (el) => {
		el.addEventListener('change', () => submitTopicFilter());
	});
}

export function setTopicIds() {
	const topicIds = inputs('.topic-filter')
		.filter((el) => el.checked)
		.map((el) => el.dataset.id)
		.join(',');

	input('#topicIds').value = topicIds;
}

function submitTopicFilter() {
	setTopicIds();

	input('#pageNo').value = '1';

	form('#searchForm').submit();
}

export function initSwitchMode() {
	type Mode = 'tiles' | 'list';

	const value = input('#mode').value as Mode;

	if (isEl('#switch')) {
		onClick('#switch', () => {
			if (value == 'list') {
				input('#mode').value = 'tiles';
			} else if (value == 'tiles') {
				input('#mode').value = 'list';
			}

			submitTopicFilter();
		});
	}
}

export function initMissSpell() {
	scan('a.term', (el) => {
		el.addEventListener('click', () => {
			input('#search-input').value = el.dataset.word;
			form('#searchForm').submit();
		});
	});
}

function prepareSubmit() {
	input('#keywordId').value = '';
	input('#pageNo').value = '1';

	return true;
}

function submitOnEnter() {
	const searchForm = form('#searchForm');
	const searchInput = input('#search-input');

	searchInput.addEventListener('keyup', (event) => {
		if (event.key === 'Enter') {
			prepareSubmit();
			searchForm.submit();
		}
	});
}
