export function addClass(selector: string, className: string) {
	const el = document.querySelector(selector);

	if (el) {
		!el.classList.contains(className) && el.classList.add(className);
	} else {
		console.log(`Remove Class: ${className}, Missing element${selector}`);
	}
}

export function removeClass(selector: string, className: string) {
	const el = document.querySelector(selector);

	if (el) {
		el.classList.contains(className) && el.classList.remove(className);
	} else {
		console.log(`Remove Class: ${className}, Missing element${selector}`);
	}
}

export function getEl<T extends Element = HTMLElement>(selector: string) {
	const el = document.querySelector(selector);

	if (el) {
		return el as T;
	} else {
		throw Error(`Element not found - selector: ${selector}!`);
	}
}

export function getEls<T extends Element = HTMLElement>(selector: string) {
	return document.querySelectorAll(selector) as NodeListOf<T>;
}

export function arrEls<T extends Element = HTMLElement>(selector: string) {
	return Array.from(document.querySelectorAll(selector) as NodeListOf<T>);
}

export function scan<T extends Element = HTMLElement>(
	selector: string,
	hnd: (el: T, key: number, parent: NodeListOf<T>) => void
) {
	const inputs = getEls<T>(selector);

	inputs.forEach((el, key, parent) => hnd(el, key, parent));
}

export const scanInputs = (
	selector: string,
	hnd: (el: HTMLInputElement) => void
) => scan(selector, hnd);

export const el = (selector: string) => getEl(selector);
export const els = (selector: string) => arrEls(selector);
export const input = (selector: string) => getEl<HTMLInputElement>(selector);
export const button = (selector: string) => getEl<HTMLButtonElement>(selector);
export const inputs = (selector: string) => arrEls<HTMLInputElement>(selector);
export const form = (selector: string) => getEl<HTMLFormElement>(selector);

export const isEl = (selector: string) =>
	document.querySelector(selector) ? true : false;

export const onReady = (handler: () => void) =>
	document.addEventListener('DOMContentLoaded', handler);

export const onClick = <T extends Element = HTMLElement>(
	selector: string,
	listener: (el: T, ev: MouseEvent) => void
) =>
	el(selector).addEventListener('click', function (ev) {
		listener(this as unknown as T, ev);
	});

export const onEvent = <
	K extends keyof HTMLElementEventMap,
	T extends Element = HTMLElement
>(
	selector: string,
	type: K,
	listener: (el: T, ev: HTMLElementEventMap[K]) => void
) =>
	el(selector).addEventListener(type, function (ev) {
		listener(this as unknown as T, ev);
	});
