import { addClass, button, el, form, input, onClick, scan } from '../util';
import { setTopicIds } from './SearchForm';

export function initPager() {
	// TODO
	prepare();
}

function prepare() {
	const count = window.pageCount;

	if (count < 2) {
		el('#pager').style.display = 'none';
	} else {
		initLinks(count);
		initButtons(count);
	}
}

function initButtons(count: number) {
	const pageNo = getPageNo();

	if (pageNo > 1) {
		onClick('#pagerPrev', () => {
			goToPage((pageNo - 1).toString());
		});
	} else {
		button('#pagerPrev').disabled = true;
	}

	if (pageNo < count) {
		onClick('#pagerNext', () => {
			goToPage((pageNo + 1).toString());
		});
	} else {
		button('#pagerNext').disabled = true;
	}
}

function getPageNo() {
	return parseInt(input('#pageNo').value);
}

function initLinks(count: number) {
	const pageNo = getPageNo();
	const navEls = [];

	if (count <= 6) {
		for (let i = 1; i <= count; i++) {
			navEls.push(link(i));
		}
	} else {
		navEls.push(link(1));

		if (pageNo < 4) {
			navEls.push(link(2));
			navEls.push(link(3));
			navEls.push(link(4));

			navEls.push(hellip());
		} else {
			navEls.push(hellip());

			if (pageNo > count - 3) {
				navEls.push(link(count - 3));
				navEls.push(link(count - 2));
				navEls.push(link(count - 1));
			} else {
				navEls.push(link(pageNo - 1));
				navEls.push(link(pageNo));
				navEls.push(link(pageNo + 1));

				navEls.push(hellip());
			}
		}

		navEls.push(link(count));
	}

	el('#pagerNav').innerHTML = navEls.join('\n');

	addClass(`#pagerNav a[data-pos='${pageNo}']`, 'actual');
	el(`#pagerNav a[data-pos='${pageNo}']`).setAttribute('aria-current', 'page');

	prepareLinks();
}

function prepareLinks() {
	scan('#pagerNav a', (el) => {
		el.addEventListener('click', () => {
			goToPage(el.dataset.pos);
		});
	});
}

function goToPage(pos: string) {
	setTopicIds();

	input('#pageNo').value = pos;

	form('#searchForm').submit();
}

function hellip() {
	return `<span>&hellip;</span>`;
}

function link(pos: number) {
	return `<a id="pageLink${pos}" data-pos="${pos}" href="#">${pos}</a>`;
}
