type StorageType = 'session' | 'local';

//TODO: consider to use
// type CookieGroupOption = 'in' | 'out' | 'mandatory';

type CookieOption = 'all' | 'base';

export default () => {
	const cookieBanner = globalThis.cookieBanner;

	const cookieStatus = localStorage.getItem(cookieBanner.cookiename);

	const cookieSession = sessionStorage.getItem(cookieBanner.cookiename);

	const showPanel = !(cookieStatus || cookieSession);
	const showWrapper = !(cookieStatus || cookieSession);
	const showModal = false;
	const showDetails = false;
	const currentGroup = -1;
	const groups = [];
	const cookieGroups = cookieBanner.groups;

	//TODO: separate to functions
	if (cookieStatus) {
		if (cookieStatus == 'all') {
			cookieGroups.forEach((element) => {
				groups.push(element.systemname);
			});
		} else {
			cookieStatus.split(',').forEach((el) => {
				groups.push(el);
			});
		}
	} else {
		if (cookieSession) {
			cookieGroups.forEach((element) => {
				if (element.cgroupType == 'mandatory') {
					groups.push(element.systemname);
				}
			});
		} else {
			cookieGroups.forEach((element) => {
				if (element.cgroupType != 'out') {
					groups.push(element.systemname);
				}
			});
		}
	}

	const setStatus = function (storageType: StorageType, value: CookieOption) {
		const storage: Storage =
			storageType === 'session' ? sessionStorage : localStorage;


		if (value == 'all') {
			cookieGroups.forEach((element) => {
				this.groups.push(element.systemname);
			});
			storage.setItem(cookieBanner.cookiename, value);
		} else if (value == 'base') {
			this.groups = [];
			console.log(cookieGroups);
			cookieGroups.forEach((element) => {
				if (element.cgroupType == 'mandatory') {
					this.groups.push(element.systemname);
					console.log(element.systemname);
				}
			});
			storage.setItem(cookieBanner.cookiename, this.groups.join(','));
		}

		this.showPanel = false;
		this.showWrapper = false;
	};

	const setCookieGroups = function () {
		localStorage.setItem(cookieBanner.cookiename, this.groups.join(','));
		document.body.style.overflow = '';
		this.showModal = false;
		this.showPanel = false;
		this.showWrapper = false;
	};

	const openModal = function () {
		document.body.style.overflow = 'hidden';
		this.$dispatch('open-modal', true);
		this.showPanel = false;
		this.showWrapper = true;
	};

	const openDetails = function (id: number) {
		this.currentGroup = id;
		this.showModal = false;
		this.showDetails = true;
	};

	return {
		cookieBanner,
		showPanel,
		showWrapper,
		showModal,
		showDetails,
		groups,
		currentGroup,
		openModal,
		setStatus,
		setCookieGroups,
		openDetails,
	};
};
